import React, {useEffect, useState, useContext} from "react";
import { Card, CardBody, Spinner, Progress} from 'reactstrap';
import justcastApi from '../../api/justcast';
import {Context as MenuContext} from '../../context/MenuContext'
import {Context as ScreenContext} from '../../context/ScreenContext'
import axios from 'axios';
import TranscriptRequestForm from '../../components/transcript-requests/TranscriptRequestForm'
import AudioPlayer from './components/AudioPlayer'
import ToastAlert from "./components/ToastAlert"
import ModalMessage from '../../components/ModalMessage'

export default function TeamTranscriptRequest(props) {
  const {show_id, id} = props.match.params;
  const [transcriptId, setTranscriptId] = useState(null);
  const menuContext = useContext(MenuContext);
  const screenContext = useContext(ScreenContext);
  const [missingTranscript, setMissingTranscript] = useState(false);
  const [episode, setEpisode] = useState(null);
  const [requestId, setRequestId] = useState(null);
  const [requestProgressStatus, setRequestProgressStatus] = useState(null);
  const [title, setTitle] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [transcript, setTranscript] = useState([]);
  const [toastMessage, setToastMessage] = useState(null)
  const [toastTitle, setToastTitle] = useState(null)
  const [showToast, setShowToast] = useState(false)
  const [startTimes, setStartTimes] = useState([]);
  const [fileLinks, setFileLinks] = useState([]);
  const [transcriptUrl, setTranscriptUrl] = useState(null);
  const [modalTranscriptOpen, setModalTranscriptOpen] = useState(false);
  const [transcriptResponseMessage, setTranscriptResponseMessage] = useState(null);
  const [transcriptModalTitle, setTranscriptModalTitle] = useState(null);

  const toggleTranscript = () => {
    setModalTranscriptOpen(!modalTranscriptOpen)
  }

  const toggleToast = () => {
    setShowToast(!showToast);
  }

  useEffect(() => {
    justcastApi.get(`/v2/team_podcasts/${show_id}/team_audioposts/${id}/transcript_request.json`)
    .then((res) => {
      const data = res.data
      const {
        show,
        audiopost,
        transcript_json_url
      } = res.data;
      screenContext.finishFetching();
      setTranscriptId(res.data.id);
      setTranscriptUrl(transcript_json_url)
      setFileLinks([
        {name: "Download Text", href: res.data.transcript_txt_url},
        {name: "SRT", href: res.data.transcript_srt_url},
        {name: "VTT", href: res.data.transcript_vtt_url},
        {name: "JSON", href: res.data.transcript_json_url},
      ])

      setEpisode(audiopost);

      setTitle(audiopost.name);
      setAudioUrl(audiopost.audio_src);

      setMissingTranscript(transcript_json_url ? false : true)
      setRequestId(data.id)
      setRequestProgressStatus(data.progress_status)

      menuContext.setTeamEpisodePageMenus({
        title: 'Transcript',
        subtitle: `${show.podcast_title} > ${audiopost.name}`,
        currentPageId: 'episode',
        currentTabId: 'transcript',
        showId: show_id,
        episodeId: audiopost.id,
        landingPageUrl: show.landing_page_url,
        websiteUrl: show.player_page_link,
        rssFeed: show.rss_feed,
        passwordProtected: show.is_password_protected,
        advancedPrivate: show.is_advanced_private_feed,
        isPrivate: show.is_private,
        isPrivateShow: show.is_private_show,
      });

      return transcript_json_url
    })
    .catch((err) => {
      // maybe redirect
      return
    })
  }, [show_id, id])

  useEffect(() => {
    if(transcriptUrl) {
      axios.get(transcriptUrl).then((res) => {
        setTranscript(res.data)

        setStartTimes(res.data.map(({start}) => start))
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }, [transcriptUrl])

  const handleCreateTranscript = (event) => {
    justcastApi.post(`/v2/team_podcasts/${show_id}/team_audioposts/${id}/team_audiopost_transcript_requests.json`)
    .then((res) => {
      setRequestId(res.data.id)
      setRequestProgressStatus(res.data.progress_status);
      setModalTranscriptOpen(true)
      setTranscriptResponseMessage("Feel free to close this window. Typically, a one-hour podcast takes 12 minutes to transcribe. Please check back later to review your transcript.")
      setTranscriptModalTitle("Preparing your transcript...")
    })
    .catch((err) => {
      setTranscriptModalTitle("Warning")
      setModalTranscriptOpen(true)
      setTranscriptResponseMessage("Something went wrong...")
    })
  }

  const handleEditTranscript = (paragraphId, text, setDisableEditor) => {
    const _transcript = [...transcript];
    _transcript[paragraphId]['text'] = text;
    setTranscript(_transcript)
    setDisableEditor(true)

    justcastApi.put(`/v2/team_podcasts/${show_id}/team_audioposts/${id}/team_audiopost_transcript_requests/${requestId}.json`, {
      paragraph: {
        paragraph_id: paragraphId,
        text,
      }
    })
    .then((res) => {
      // alert updated
      setDisableEditor(false)
      setShowToast(true);
      setToastMessage(res.data.message)

      setFileLinks([
        {name: "Download Text", href: res.data.transcript_txt_url},
        {name: "SRT", href: res.data.transcript_srt_url},
        {name: "VTT", href: res.data.transcript_vtt_url},
        {name: "JSON", href: res.data.transcript_json_url},
      ])

      setToastTitle("Success")
    })
    .catch((err) => {
      // notice failed
      setDisableEditor(false)
    })
  }

  if(screenContext.state.loading) {
    return (
      <div className="container-fluid">
        <Spinner color="primary" />
      </div>
    )
  }

  if(missingTranscript) {
    return (
      <div className="container-fluid" style={{paddingBottom: 20}}>
        <ModalMessage
          title={transcriptModalTitle}
          isOpen={modalTranscriptOpen}
          toggle={toggleTranscript}>
          <p>{transcriptResponseMessage}</p>
        </ModalMessage>
        <Card>
          <CardBody>
            <TranscriptRequestForm
              handleSave={handleCreateTranscript}
              audiopostId={id}
              requestId={requestId}
              requestProgressStatus={requestProgressStatus}
            />
          </CardBody>
        </Card>
      </div>
    )
  }

  return (
    <div className="container-fluid">
      <ToastAlert
        title={toastTitle}
        message={toastMessage}
        isOpen={showToast}
        toggle={toggleToast}
      />
      <AudioPlayer
        title={title}
        url={audioUrl}
        fileLinks={fileLinks}
        transcript={transcript}
        startTimes={startTimes}
        handleEditTranscript={handleEditTranscript}
      />
    </div>
  );
}
