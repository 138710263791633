import React, {useState, useRef} from 'react'
import ReactPlayer from 'react-player'
import PlayerControl from './PlayerControl'
import ParagraphCard from './ParagraphBox'
import SubControl from './SubControl'
import { binarySearch } from '../../../libs/transcriptHtmlNodeParser'

const containerStyle = {
  display: 'flex',
  flexDirection: "column",
  gap: "10px",
  position: "sticky",
  top: "0px",
  backgroundColor: "#12263f",
  padding: "20px 0 20px 0",
  zIndex: 2,
}

const wrapper = {
  display: 'flex',
  flexDirection: "column",
  gap: "20px"
}

export default function AudioPlayer(props) {
  const { url, transcript, handleEditTranscript, fileLinks, handleSpeakerName} = props;
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [durationStr, setDurationStr] = useState(null);
  const [played, setPlayed] = useState(null);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [onSeeking, setOnSeeking] = useState(true);

  const handlePlayPauseClick = () => {
    setPlaying(!playing)
  }

  const handleBackward = () => {
    const seekTo = Math.max(playedSeconds-3, 0)
    playerRef.current.seekTo(seekTo, 'seconds')
  }

  const handleForward = () => {
    const seekTo = Math.min(playedSeconds+5, duration)
    playerRef.current.seekTo(seekTo, 'seconds')
  }

  const playParagraph = (seconds) => {
    playerRef.current.seekTo(seconds, 'seconds');
    if(!playing) {
      setPlaying(true);
    }
  }

  const pausePlayer = () => {
    setPlaying(false);
  }

  const jumpToParagraph = () => {
    const id = binarySearch(transcript.startTimes, playedSeconds)
    if(id < 2) {
      return null;
    }
    const childNode = document.getElementById(`transcript-${id}`);
    childNode.scrollIntoView({ behavior: 'smooth' });
  }

  if(!transcript) {
    return null;
  }

  if(transcript.paragraphs.length === 0) {
    return null;
  }

  // const { id, title, paragraph, onSave, isOpen, toggle } = props;
  return (
    <>
      <div style={wrapper}>
        <div style={containerStyle}>
          <PlayerControl
            playing={playing}
            handlePlayPauseClick={handlePlayPauseClick}
            handleBackward={handleBackward}
            handleForward={handleForward}
            durationStr={durationStr}
            played={played}
          />
          <input
            className='jc-editor-player-slider'
            type="range"
            min={0}
            max={duration}
            step={0.01}
            value={playedSeconds}
            onChange={(e) => {
              setPlayedSeconds(Number(e.target.value));
            }}
            onPointerDown={() => {
              setOnSeeking(false);
            }}
            onPointerUp={() => {
              setOnSeeking(true)
              playerRef.current.seekTo(playedSeconds, 'seconds')
            }}
            style={{
              cursor: "pointer",
              display: "flex",
              background: `linear-gradient(90deg, #53a0e4 ${duration <= 1 ? 0 : playedSeconds * 100 / duration}%,  #d6f6fa ${duration <= 1 ? 0 : playedSeconds * 100 / duration}% )`
            }}
          />
          <SubControl fileLinks={fileLinks} jumpToParagraph={jumpToParagraph}/>
        </div>
        <div>
          {
            transcript.paragraphs.map((paragraph, idx) => {
              return <ParagraphCard
                key={idx}
                id={idx}
                text={paragraph.text}
                speaker={paragraph.speaker}
                startTime={paragraph.start}
                onClick={playParagraph}
                pausePlayer={pausePlayer}
                handleSpeakerName={handleSpeakerName}
                onEdit={handleEditTranscript}/>
              }
           )
          }
        </div>
      </div>
      <ReactPlayer
        ref={playerRef}
        url={url}
        controls={false}
        width="0%"
        height="0%"
        onDuration={(res) => {
          const date = new Date(null);
          date.setSeconds(res);
          setDuration(res);
          const durationTimeStr = date.toISOString().slice(11, 19);
          setDurationStr(durationTimeStr)
        }}
        progressInterval={50}
        onProgress={(res) => {
          if(onSeeking) {
            const date = new Date(null);
            date.setSeconds(res.playedSeconds);
            setPlayedSeconds(res.playedSeconds)
            setPlayed(date.toISOString().slice(11, 19))
          }
        }}
        onEnded={() => {
          setPlaying(false);
        }}
        playing={playing}
      />
    </>
  )
}
