import React from 'react'
import { Play, Pause, RotateCcw, RotateCw } from 'react-feather';
import { Button} from "reactstrap";

const containerStyle = {
  display: "flex",
  justifyContent: "space-between",
}

const buttonContainerStyle = {
  display: "flex",
  gap: "6px"
}

const controlStyle = {
  display: "flex",
  gap: "20px"
}

const playTimeContainer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: '4px',
}

const PlayerControl = (props) => {

  const {playing, handlePlayPauseClick, handleBackward, handleForward, played, durationStr} = props;

  return (
    <div style={containerStyle}>
      <div style={controlStyle}>
        <Button
          color="primary"
          size="sm"
          name="play button"
          onClick={handlePlayPauseClick}
        >
          {playing ? <i className='fe fe-pause'/> : <i className='fe fe-play'/> }
        </Button>
        <div style={buttonContainerStyle}>
          <Button
            color="secondary"
            size="sm"
            name="backward 5 seconds"
            onClick={handleBackward}
          >
            <i className='fe fe-rotate-ccw'/> {" "} -3s
          </Button>
          <Button
            color="secondary"
            size="sm"
            name="forward 5 seconds"
            onClick={handleForward}
          >
            <i className='fe fe-rotate-cw'/> {" "} +5s
          </Button>
        </div>
      </div>
      <div style={playTimeContainer}>
        <small>{played}</small>
        <span>/</span>
        <small>{durationStr}</small>
      </div>
    </div>
  )
}

export default PlayerControl;