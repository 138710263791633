import React from 'react'
import { Button, Spinner, Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";

const TranscriptRequestForm = (props) => {

  const { handleSave, audiopostId, requestId, requestProgressStatus } = props;

  if(requestProgressStatus) {
    return (
      <Row className="align-items-center">
        <Col>
          <h4>{requestProgressStatus}</h4>
        </Col>
        <Col className="col-auto">
          {requestProgressStatus === "COMPLETED" ? <Link to={`/episodes/${audiopostId}/transcripts/${requestId}`} className="btn btn-primary btn-sm">View</Link> : <Spinner/>}
        </Col>
      </Row>
    )
  }

  return (
    <div>
      <h4 className="alert-heading">Missing transcript</h4>
      <p>This episode has not been transcribed yet. Would you like to generate one?</p>
      <Button onClick={handleSave}>Generate transcript</Button>
    </div>
  )
}

export default TranscriptRequestForm;