import React, { useState, useRef, useCallback } from 'react';
import { useEditable } from 'use-editable';
import { Button, Card, CardBody, CardFooter, CardHeader, Input} from 'reactstrap';

const buttonContainer = {
  display: 'flex',
  gap: 10,
  alignItems: 'center'
}

const wrapper = {
  display: 'flex',
  gap: 10,
  alignItems: 'center',
  justifyContent: 'space-between'
}

const ParagraphCard = (props) => {
  const {id, text, startTime, speaker, onClick, onEdit, pausePlayer, handleSpeakerName } = props;
  const [disabled, setDisabled] = useState(true);
  const [disableEditor, setDisableEditor] = useState(false);

  const date = new Date(null);
  date.setSeconds(startTime);
  const startTimeStr = date.toISOString().slice(11, 19);

  const editorRef = useRef(null);

  const onEditableChange = useCallback((text) => {
    setDisabled(false);
    setParagraph(text);
  }, []);

  const reset = () => {
    editorRef.current.textContent = text;
    setDisabled(true);
  }

  const handleSave = () => {
    onEdit(id, editorRef.current.textContent, setDisableEditor)
    setDisabled(true);
  }

  const [paragraph, setParagraph] = useState(text);
  useEditable(editorRef, onEditableChange, {
    disabled: disableEditor
  });

  return (
    <Card id={`transcript-${id+1}`}>
      <CardHeader>
        <div style={wrapper}>
          <div style={buttonContainer}>
            <div className='jc-transcript-paragraph-time-button' onClick={() => {
              handleSpeakerName(id)
            }}>
              {speaker}
            </div>
            <div
              className='jc-transcript-paragraph-time-button text-muted'
              onClick={() => {
                onClick(startTime)
              }}
            >
              {startTimeStr}
            </div>
          </div>
          <div style={buttonContainer}>
            <Button size="sm" onClick={reset} disabled={disabled}>Reset</Button>
            <Button
              disabled={disabled}
              color="primary"
              size="sm"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <p
          ref={editorRef}
          onClick={pausePlayer}
        >
          {paragraph}
        </p>
      </CardBody>
    </Card>
  )
}

export default ParagraphCard;