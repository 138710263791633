import React, {useState, useRef} from 'react'
import { Toast, ToastBody, ToastHeader} from 'reactstrap';


const ToastAlert = (props) => {
  const { toggle, isOpen, title, message} = props;

  return (
    <div className="p-3 my-2 rounded">
      <Toast isOpen={isOpen}>
        <ToastHeader toggle={toggle}>
          {title}
        </ToastHeader>
        <ToastBody>
          {message}
        </ToastBody>
      </Toast>
    </div>
  )
}

export default ToastAlert