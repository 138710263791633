import React, {useEffect, useState, useContext, useReducer} from "react";
import { Spinner } from 'reactstrap';
import justcastApi from '../../api/justcast';
import {Context as MenuContext} from '../../context/MenuContext'
import {Context as ScreenContext} from '../../context/ScreenContext'
import SpeakerModal from "./components/SpeakerModal"
import AudioPlayer from './components/AudioPlayer'
import ToastAlert from "./components/ToastAlert"
import axios from 'axios';
import _ from "underscore"

const reducer = ((prevData, action) => {
  const transcript = {...prevData };
  const {id, text, speaker } = action;

  switch(action.type) {
    case 'initial':
      transcript.paragraphs = action.paragraphs
      const startTimes = transcript.paragraphs.map(({start}) => start)
      return { ...transcript, startTimes}
    case 'update_paragraph':
      transcript.paragraphs[id]["text"] = text;
      return {...transcript, paragraphs: transcript.paragraphs}
    case 'update_speaker':
      transcript.paragraphs[id]["speaker"] = speaker;
      return {...transcript, paragraphs: transcript.paragraphs}
    case 'update_all_speaker':
      const existingSpeakerName = transcript.paragraphs[id]["speaker"]
      const n = transcript.paragraphs.length;

      for(let i = 0; i < n; i++) {
        if(transcript.paragraphs[i]["speaker"] === existingSpeakerName) {
          transcript.paragraphs[i]["speaker"] = speaker;
        }
      }
      return {...transcript, paragraphs: transcript.paragraphs}
  }

  return transcript
})

const modalReducer = ((prevData, data) => {
  return {...prevData, ...data};
})

const TranscriptRequest= (props) => {
  const {audiopost_id, id} =  props.match.params;
  const {setAiContentPageMenu} = useContext(MenuContext);
  const screenContext = useContext(ScreenContext);
  const [title, setTitle] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [transcriptUrl, setTranscriptUrl] = useState(null);
  const [toastMessage, setToastMessage] = useState(null)
  const [toastTitle, setToastTitle] = useState(null)
  const [showToast, setShowToast] = useState(false)
  const [fileLinks, setFileLinks] = useState([]);

  const [transcript, updateTranscript] = useReducer(reducer, {paragraphs: [], startTimes: []})
  const [modal, updateModal] = useReducer(modalReducer, {isOpen: false, text: null, speaker: null, paragraphId: null, replaceAll: false})

  const toggleToast = () => {
    setShowToast(!showToast);
  }

  const toggleModal = () => {
    updateModal({isOpen: !modal.isOpen})
  }

  useEffect(() => {
    if(showToast) {
      let timerId = setTimeout(() => {
        // perform an action like state update
        timerId = null;
        setShowToast(false);
      }, 2000);

      // clear the timer when component unmouts
      return () => clearTimeout(timerId);
    }
  }, [showToast])

  useEffect(() => {
    screenContext.startFetching();
    justcastApi.get(`/v2/audioposts/${audiopost_id}/audiopost_transcript_requests/${id}.json`)
    .then((res) => {
      screenContext.finishFetching();
      const {
        show,
        audiopost,
        transcript_json_url
      } = res.data;

      // setEpisode(audiopost);
      setTranscriptUrl(transcript_json_url)

      setFileLinks([
        {name: "Download Text", href: res.data.transcript_txt_url},
        {name: "SRT", href: res.data.transcript_srt_url},
        {name: "VTT", href: res.data.transcript_vtt_url},
        {name: "JSON", href: res.data.transcript_json_url},
      ])

      setTitle(audiopost.name);
      setAudioUrl(audiopost.audio_src);

      setAiContentPageMenu({
        title: "Transcript",
        currentPageId: "episodes",
        currentTabId: "segments",
        // subtitle: audiopost.name,
        subtitle: `${show.podcast_title} > ${audiopost.name}`,
        showName: show.podcast_title,
        showId: show.id,
        episodeId: audiopost_id,
        transcriptId: id,
        landingPageUrl: show.landing_page_url,
        websiteUrl: show.player_page_link,
        rssFeed: show.rss_feed,
        passwordProtected: show.is_password_protected,
        advancedPrivate: show.is_advanced_private_feed,
        isPrivate: show.is_private,
        isPrivateShow: show.is_private_show,
        skill_podcast_website: show.skill_podcast_website
      })
    })
    .catch((err) => {
      screenContext.finishFetching();
    })
  }, [])

  useEffect(() => {
    if(transcriptUrl) {
      axios.get(transcriptUrl).then((res) => {
        // console.log
        updateTranscript({type: "initial", paragraphs: res.data})
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }, [transcriptUrl])

  const handleEditTranscript = (paragraphId, text, setDisableEditor) => {

    updateTranscript({type: "update_paragraph", id: paragraphId, text})
    setDisableEditor(true)

    justcastApi.put(`/v2/audioposts/${audiopost_id}/audiopost_transcript_requests/${id}.json`, {
      paragraph: {
        paragraph_id: paragraphId,
        text,
      }
    })
    .then((res) => {
      // alert updated
      setDisableEditor(false)
      setShowToast(true);
      setToastMessage(res.data.message)

      setFileLinks([
        {name: "Download Text", href: res.data.transcript_txt_url},
        {name: "SRT", href: res.data.transcript_srt_url},
        {name: "VTT", href: res.data.transcript_vtt_url},
        {name: "JSON", href: res.data.transcript_json_url},
      ])

      setToastTitle("Success")
    })
    .catch((err) => {
      // notice failed
      setDisableEditor(false)
    })
  }

  const handleSpeakerName = (paragraphId) => {
    const paragraph = transcript.paragraphs[paragraphId];
    const { text, speaker} = paragraph;
    updateModal({text, speaker, paragraphId, replaceAll: false, isOpen: true})
  }

  const saveSpeakerName = (data) => {

    const {speaker, replace_all, paragraph_id} = data;

    if(replace_all) {
      updateTranscript({type: "update_all_speaker", speaker, id: paragraph_id})
    } else {
      updateTranscript({type: "update_speaker", speaker, id: paragraph_id})
    }

    justcastApi.post(`/v2/audioposts/${audiopost_id}/audiopost_transcript_requests/${id}/update_speaker.json`, data)
    .then((res) => {
      // console.log(res.data)
      updateModal({isOpen: false, text: null, speaker: null, paragraphId: null, replaceAll: false})

      setShowToast(true);
      setToastTitle("Success")
      setToastMessage(res.data.message)
    })
    .catch((err) => {
      console.log(err)
    })
  }

  if(screenContext.state.loading) {
    return (
      <div className="container-fluid">
        <Spinner color="primary" />
      </div>
    )
  }

  return (
    <div className="container-fluid">
      <SpeakerModal
        toggle={toggleModal}
        text={modal.text}
        speaker={modal.speaker}
        paragraphId={modal.paragraphId}
        replaceAll={modal.replaceAll}
        isOpen={modal.isOpen}
        onClick={saveSpeakerName}
      />
      <ToastAlert
        title={toastTitle}
        message={toastMessage}
        isOpen={showToast}
        toggle={toggleToast}
      />
      <AudioPlayer
        title={title}
        url={audioUrl}
        fileLinks={fileLinks}
        transcript={transcript}
        handleEditTranscript={handleEditTranscript}
        handleSpeakerName={handleSpeakerName}
      />
    </div>
  );
}

export default TranscriptRequest;