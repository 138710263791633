import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert, Label, Form, FormGroup, FormText} from 'reactstrap';
import { useForm } from "react-hook-form";


const SpeakerModal = ({isOpen, toggle, speaker, text, paragraphId, replaceAll, onClick}) => {

  const { register, handleSubmit, formState: { errors } } = useForm({
    values: {
      replace_all: replaceAll,
      speaker,
      paragraph_id: paragraphId,
    }
  });

  const handleSave = (data, event) => {
    event.preventDefault();
    onClick(data)
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>
        <strong>Match {speaker}</strong>
      </ModalHeader>
      <ModalBody>
        <p className="text-muted">Match the right speaker with this paragraph.</p>
        <Alert color="dark">{text}</Alert>
        <Form onSubmit={handleSubmit(handleSave)}>
          <FormGroup>
            <Label for="speaker">Speaker</Label>
            <input
              type="text"
              className="form-control"
              placeholder={speaker} {...register("speaker", {required: true})} />
            {errors.start_time && <FormText color="danger">Speaker can't be blank</FormText>}
          </FormGroup>
          <FormGroup check inline>
            <Label check>
              <input type="checkbox" name="replace_all" className='form-check-input'
              {...register("replace_all")}
              />{`Apply change to all ${speaker} sections`}
            </Label>
          </FormGroup>
          {/* <Button onClick={handleSubmit(onSubmit)}>Generate transcript</Button> */}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>Close</Button>
        <Button color="primary" onClick={handleSubmit(onClick)}>Save</Button>
      </ModalFooter>
    </Modal>
  )
}

export default SpeakerModal;