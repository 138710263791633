import React, {useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';

const SubControl = (props) => {
  const {fileLinks, jumpToParagraph} = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  const style = {
    marginTop: 10,
    display: "flex",
    gap: 10
  }

  return (
    <div style={style}>
      <Dropdown isOpen={dropdownOpen} toggle={toggle} size="sm">
        <DropdownToggle caret>
          Dropdown transcript
        </DropdownToggle>
        <DropdownMenu>
          {fileLinks?.map((fileUrl, id) => {
            return (
              <DropdownItem key={id}>
                <a href={fileUrl.href}>{fileUrl.name}</a>
              </DropdownItem>
            )
          })}
        </DropdownMenu>
      </Dropdown>
      <Button size="sm" onClick={jumpToParagraph}>Jump to script</Button>
    </div>
  )
}

export default SubControl;