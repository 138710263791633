import React, {useEffect, useState, useContext} from "react";
import { Card, CardBody, CardHeader, Button, Spinner} from 'reactstrap';
import justcastApi from '../../api/justcast';
import {updateSelectedPeople} from '../../libs/updateSelectedPeople'
import {Context as AlertContext} from '../../context/AlertContext'
import {Context as MenuContext} from '../../context/MenuContext'
import { EditEpisodeForm, EpisodeHosts } from '../../components/episode'
import {SeasonForm} from '../../components/seasons'
import ModalMessage from '../../components/ModalMessage'
import TranscriptRequestForm from '../../components/transcript-requests/TranscriptRequestForm'
import { Link } from "react-router-dom";

const EpisodePage = (props) => {
  const {addWithTimeout} = useContext(AlertContext);
  const [loading, setLoading] = useState(true);
  const [pageError, setPageError] = useState(false);
  const {show_id, id} = props.match.params;
  const {setEpisodePageMenu} = useContext(MenuContext);
  const [explicitTypes, setExplicitTypes] = useState([])
  const [episodeTypes, setEpisodeTypes] = useState([])
  const [seasons, setSeasons] = useState([]);
  const [seasonId, setSeasonId] = useState(null);
  const [seasonModalOpen, setSeasonModalOpen] = useState(false);
  const [errorSeasonMessages, setSeasonErrorMessages] = useState({});
  const [audiopostData, setAudiopostData] = useState(null);
  const [isDropbox, setIsDropbox] = useState(true);
  const [modalTranscriptOpen, setModalTranscriptOpen] = useState(false);
  const [transcriptResponseMessage, setTranscriptResponseMessage] = useState(null);
  const [transcriptModalTitle, setTranscriptModalTitle] = useState(null);

  const [people, setPeople] = useState([]); // podcast level
  const [hosts, setHosts] = useState([]) // episode level
  const [personTaxonomies, setPersonTaxonomies] = useState([]);
  const [slug, setSlug] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [requestId, setRequestId] = useState(null);
  const [requestProgressStatus, setRequestProgressStatus] = useState(null);

  const [newSeason, setNewSeason] = useState({
    name: "", number: "", id: null
  });

  const [transcripts, setTranscripts] = useState([]);

  useEffect(() => {
    justcastApi.get(`/v2/shows/${show_id}/audioposts/${id}`)
    .then((res) => {
      setLoading(false);
      const data = res.data;
      setIsPrivate(data.show.is_private_show);
      setSlug(res.data.show.slug);
      setAudiopostData(data);
      setEpisodeTypes(data.episode_types)
      setExplicitTypes(data.explicit_types);
      setSeasonId(data.season_id)
      setIsDropbox(data.is_dropbox);

      setRequestId(data.audiopost_transcript_request_id)
      setRequestProgressStatus(data.audiopost_transcript_request_progress_status)

      if(data.seasons) {
        setSeasons(data.seasons)
      }

      if(data.hosts && data.hosts.length > 0) {
        setHosts(data.hosts)
      }

      if(data.people && data.people.length > 0) {

        const _people = updateSelectedPeople({
          hosts: data.hosts,
          people: data.people
        })
        setPeople(_people);
      };

      if(data.person_taxonomies && data.person_taxonomies.length > 0) {
        const options = data.person_taxonomies.map((x, idx) => {
          return {
            value: x.code, label: x.role_title, ...x
          }
        });

        setPersonTaxonomies(options)
      }

      setEpisodePageMenu({
        title: "Settings",
        subtitle: `${data.show.podcast_title} > ${data.episode_title}`,
        currentPageId: "episodes",
        currentTabId: "settings",
        showName: data.show.podcast_title,
        showId: show_id,
        episodeId: id,
        landingPageUrl: data.show.landing_page_url,
        websiteUrl: data.show.player_page_link,
        rssFeed: data.show.rss_feed,
        passwordProtected: data.show.is_password_protected,
        advancedPrivate: data.show.is_advanced_private_feed,
        isPrivate: data.show.is_private,
        isPrivateShow:data.show.is_private_show,
        skill_podcast_website: data.show.skill_podcast_website,
        r2Uploaded: data.r2_uploaded
      })
    })
    .catch((err) => {
      //TODO alert something went wrong
      setLoading(false)
      setPageError(true);
      console.log(err)
    })
  },[show_id, id])



  const handleAddSeason = () => {
    setSeasonModalOpen(true);
  }

  const handleNewSeasonModalToggle = () => {
    setSeasonModalOpen(false);
  }

  const handleHostsChanged = (_hosts) => {
    const _people = updateSelectedPeople({hosts: _hosts, people})
    setPeople(_people);
    setHosts(_hosts);
  }

  const handleInputNewSeasonChange = (evt) => {
    const name = evt.target.name;
    const value = evt.target.value;
    setNewSeason({...newSeason, [name]: value});
  }

  const handleSeasonSave = () => {
    justcastApi.post(`/v1/shows/${show_id}/seasons`, {
      name: newSeason.name,
      number: newSeason.number
    })
    .then((res) => {
      setSeasonErrorMessages({})
      setNewSeason({name: "", number: "", id: null})
      setSeasonId(res.data.id);
      setSeasons([res.data, ...seasons].sort((a, b) => b['number'] - a['number']));
      setSeasonModalOpen(false);
      addWithTimeout({color: 'primary', message: `Added a new season`, isOpen: true, timeout: 3000});
    })
    .catch((err) => {
      setSeasonErrorMessages(err.response.data)
    })
  }

  const handleCreateTranscript = (event) => {
    event.preventDefault();

    justcastApi.post(`/v2/audioposts/${id}/audiopost_transcript_requests.json`)
    .then((res) => {
      setRequestProgressStatus(res.data.progress_status);
      setModalTranscriptOpen(true)
      setTranscriptResponseMessage("Feel free to close this window. Typically, a one-hour podcast takes 12 minutes to transcribe. Please check back later to review your transcript.")
      setTranscriptModalTitle("Preparing your transcript...")
    })
    .catch((err) => {
      setTranscriptModalTitle("Warning")
      setModalTranscriptOpen(true)
      const errorMessage = err?.response?.data?.messages || "Something went wrong..."
      setTranscriptResponseMessage(errorMessage)
    })
  }

  const toggleTranscript = () => {
    setModalTranscriptOpen(!modalTranscriptOpen)
  }

  if(loading || pageError) return null;

  return (
    <>
      <div className="container-fluid">
        <ModalMessage
          title={transcriptModalTitle}
          isOpen={modalTranscriptOpen}
          toggle={toggleTranscript}>
          <p>{transcriptResponseMessage}</p>
        </ModalMessage>
        <SeasonForm
            title={"Add a new season"}
            isOpen={seasonModalOpen}
            toggle={handleNewSeasonModalToggle}
            data={newSeason}
            errorMessages={errorSeasonMessages}
            onChange={handleInputNewSeasonChange}
          >
            <Button color="primary" onClick={handleSeasonSave}>Save</Button>
        </SeasonForm>
        <div className="row">
          <div className="col-12" style={{margin: "0 0 60px 0"}}>
            <EditEpisodeForm
              id={id}
              showId={show_id}
              seasons={seasons}
              seasonId={seasonId}
              setSeasonId={setSeasonId}
              hosts={hosts}
              explicitTypes={explicitTypes}
              episodeTypes={episodeTypes}
              handleAddSeason={handleAddSeason}
              audiopostData={audiopostData}
              isDropbox={isDropbox}
              slug={slug}
              isPrivate={isPrivate}
            >
              <>
                <Card>
                  <CardHeader>
                    <div className="row align-items-center">
                      <div className="col">
                        <h3 className="card-header-title">Transcript</h3>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <TranscriptRequestForm
                      handleSave={handleCreateTranscript}
                      audiopostId={id}
                      requestId={requestId}
                      requestProgressStatus={requestProgressStatus}
                    />
                  </CardBody>
                </Card>
                <hr/>
                <EpisodeHosts
                  showId={show_id}
                  hosts={hosts}
                  people={people}
                  setHosts={handleHostsChanged}
                  setPeople={setPeople}
                  personTaxonomies={personTaxonomies}
                />
              </>
            </EditEpisodeForm>
          </div>
        </div>
      </div>
    </>
  )
}

export default EpisodePage;